function createFirework() {
  const script = document.createElement("script");
  script.src = "https://cdn.jsdelivr.net/npm/fireworks-js@2.x/dist/index.umd.js";
  script.async = true;
  document.body.appendChild(script);
  const canvas = document.createElement("canvas");
  canvas.classList.add("firework");
  canvas.style.position = "fixed";
  canvas.style.top = "0";
  canvas.style.left = "0";
  canvas.style.width = "100%";
  canvas.style.height = "100%";
  canvas.style.zIndex = "9999";
  document.body.appendChild(canvas);
  script.onload = () => {
    const fireworks = new Fireworks.default(canvas, {
      sound: {
        enabled: true,
        files: [
          "https://raw.githubusercontent.com/crashmax-dev/fireworks-js/eedee4020e9c458fde83b60dfac6f2502d4593bb/website/public/sounds/explosion0.mp3",
          "https://raw.githubusercontent.com/crashmax-dev/fireworks-js/eedee4020e9c458fde83b60dfac6f2502d4593bb/website/public/sounds/explosion1.mp3",
          "https://raw.githubusercontent.com/crashmax-dev/fireworks-js/eedee4020e9c458fde83b60dfac6f2502d4593bb/website/public/sounds/explosion2.mp3"
        ],
        volume: {
          min: 100,
          max: 100
        }
      }
    });
    fireworks.start();
    canvas.addEventListener("click", () => {
      fireworks.stop();
      canvas.remove();
    });
  };
}
export function firework() {
  document.addEventListener("DOMContentLoaded", () => {
    const wishlistContainer = document.querySelector(".wishlist-products-container");
    if (!wishlistContainer)
      return;
    wishlistContainer.addEventListener("click", createFirework);
  });
}
export default firework;
